// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmDialog_confirm_wrap__uK5BP {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_confirm_dialog__hX7Ap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25%;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
}
.confirmDialog_confirm_wrap__uK5BP h2 {
  margin-bottom: 5px;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_confirm_button__l\\+5eO,
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_cancel_button__8sEjI {
  padding: 8px 12px;
  border-radius: 10px;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_confirm_button__l\\+5eO {
  border: 2px solid #32976b;
  color: #32976b;
  transition: all 0.3s ease-in-out;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_confirm_button__l\\+5eO:hover {
  background-color: #32976b;
  color: white;
  transition: all 0.3s ease-in-out;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_cancel_button__8sEjI {
  border: 2px solid #e74c3c;
  color: #e74c3c;
  transition: all 0.3s ease-in-out;
}
.confirmDialog_confirm_wrap__uK5BP .confirmDialog_button_wrap__9LpR2 .confirmDialog_cancel_button__8sEjI:hover {
  background-color: #e74c3c;
  color: white;
  transition: all 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ConfirmDialog/confirmDialog.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,QAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;AADJ;AAGI;;EAEE,iBAAA;EACA,mBAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;AADN;AAII;EACE,yBAAA;EACA,cAAA;EACA,gCAAA;AAFN;AAIM;EACE,yBAAA;EACA,YAAA;EACA,gCAAA;AAFR;AAMI;EACE,yBAAA;EACA,cAAA;EACA,gCAAA;AAJN;AAMM;EACE,yBAAA;EACA,YAAA;EACA,gCAAA;AAJR","sourcesContent":[".confirm_wrap {\n  position: absolute;\n  z-index: 10;\n  width: 100%;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n\n  .confirm_dialog {\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    top: 25%;\n    background-color: white;\n    padding: 25px;\n    border-radius: 10px;\n  }\n\n  h2 {\n    margin-bottom: 5px;\n  }\n\n  .button_wrap {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-between;\n\n    .confirm_button,\n    .cancel_button {\n      padding: 8px 12px;\n      border-radius: 10px;\n      background-color: transparent;\n      font-size: 12px;\n      font-weight: 600;\n      text-transform: uppercase;\n      cursor: pointer;\n    }\n\n    .confirm_button {\n      border: 2px solid #32976b;\n      color: #32976b;\n      transition: all 0.3s ease-in-out;\n\n      &:hover {\n        background-color: #32976b;\n        color: white;\n        transition: all 0.3s ease-in-out;\n      }\n    }\n\n    .cancel_button {\n      border: 2px solid #e74c3c;\n      color: #e74c3c;\n      transition: all 0.3s ease-in-out;\n\n      &:hover {\n        background-color: #e74c3c;\n        color: white;\n        transition: all 0.3s ease-in-out;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirm_wrap": `confirmDialog_confirm_wrap__uK5BP`,
	"confirm_dialog": `confirmDialog_confirm_dialog__hX7Ap`,
	"button_wrap": `confirmDialog_button_wrap__9LpR2`,
	"confirm_button": `confirmDialog_confirm_button__l+5eO`,
	"cancel_button": `confirmDialog_cancel_button__8sEjI`
};
export default ___CSS_LOADER_EXPORT___;
