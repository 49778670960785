// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timePickerField_time_wrap__IcXJa {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  max-width: 130px;
}
.timePickerField_time_wrap__IcXJa label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding-left: 0;
  margin-bottom: 5px;
}
.timePickerField_time_wrap__IcXJa input {
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/FormFields/TimePickerField/timePickerField.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAEE;EACE,WAAA;AAAJ","sourcesContent":[".time_wrap {\n  display: flex;\n  flex-direction: column;\n  margin-right: 10px;\n  max-width: 130px;\n\n  label {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 1;\n    padding-left: 0;\n    margin-bottom: 5px;\n  }\n\n  input {\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time_wrap": `timePickerField_time_wrap__IcXJa`
};
export default ___CSS_LOADER_EXPORT___;
