import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DatePickerField } from "../../FormFields";
import {
  IPlace,
  ISchedule,
  IService,
  IUser,
  TypeOfDays,
  getBookedVisitTime,
  getTime,
  getTimeNumbersInArray,
  getUnscheduleDates,
  isRequired,
  placeQueryData,
  servicePaginationData,
  userRoles,
} from "../../../shared";
import styles from "./visitForm.module.scss";
import {
  placeApi,
  scheduleApi,
  serviceApi,
  userApi,
  visitApi,
} from "../../../store/services";

interface IVisitInitialValues {
  serviceId?: string;
  placeId?: string;
  doctorId?: string;
  startTime?: string;
}

export interface IVisitForm {
  onSubmit: (values: any, form: any) => Promise<void>;
  initialValues?: IVisitInitialValues;
  visitId?: string;
  isVisitFromCalendar?: boolean;
}

export const CalendarVisitFormComponent: FC<IVisitForm> = ({
  onSubmit,
  initialValues,
  visitId,
  isVisitFromCalendar,
}) => {
  const { t } = useTranslation();

  const [unscheduleDates, setUnscheduleDates] = useState<Date[]>();
  const [openAt, setOpenAt] = useState<number[]>();
  const [closeAt, setCloseAt] = useState<number[]>();
  const [excludeTimes, setExcludeTimes] = useState<Date[]>();

  const [chosenClinic, setChosenClinic] = useState<IPlace>();
  const [chosenDate, setChosenDate] = useState<Date | undefined>();
  const [chosenDoctor, setChosenDoctor] = useState<IUser>();
  const [chosenService, setChosenService] = useState<IService>();

  const [filteredServices, setFilteredServices] = useState<IService[]>();

  const { data: fechedWorkerList } = userApi.useFetchWorkerListQuery(
    {
      page: 1,
      limit: 100,
      roles: [userRoles.doctor, userRoles.rehabilitator],
      serviceId: chosenService?._id,
      placeId: chosenClinic?._id,
    },
    {
      skip: !chosenClinic,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: fetchedHospitalList } =
    placeApi.useFetchPlaceListQuery(placeQueryData);

  const { data: fetchedServiceList } = serviceApi.useFetchServiceListQuery(
    servicePaginationData
  );

  const { data: fetchedVisitList } = visitApi.useFetchPublicVisitListQuery(
    {
      from: moment(chosenDate).startOf("day").toISOString(true),
      to: moment(chosenDate).endOf("day").toISOString(true),
      doctorId: chosenDoctor?._id,
      placeId: chosenClinic?._id,
    },
    {
      skip: !chosenDoctor || !chosenDate,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: fetchedSchedule } = scheduleApi.useFetchUserScheduleQuery(
    {
      userId: chosenDoctor?._id,
      placeId: chosenClinic?._id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !chosenDoctor,
    }
  );

  const serviceList = fetchedServiceList?.items;
  const workerList = fechedWorkerList?.items;
  const hospitalList = fetchedHospitalList?.items;
  const visitList = fetchedVisitList?.items;

  const handleFilteredDate = (date: Date) => {
    const day = date?.getDay();
    return !chosenClinic?.schedule[day as TypeOfDays].isHoliday;
  };

  const scheduleByChosenDate = fetchedSchedule?.find(
    (schedule) =>
      moment(schedule?.startAt).format("DD.MM.YYYY") ===
      moment(chosenDate).format("DD.MM.YYYY")
  );

  useEffect(() => {
    if (isVisitFromCalendar && chosenDoctor) {
      setFilteredServices(chosenDoctor?.portfolio?.services);
    }
  }, [chosenDoctor]);

  useEffect(() => {
    if (fetchedSchedule) {
      const unscheduleDatesArray = getUnscheduleDates(
        new Date(),
        moment().add(2, "month").toDate(),
        fetchedSchedule as ISchedule[]
      );
      setUnscheduleDates(unscheduleDatesArray);
    }
  }, [fetchedSchedule]);

  useEffect(() => {
    if (chosenDate && chosenService) {
      setExcludeTimes(getBookedVisitTime(visitList, chosenService as IService));
    }
  }, [chosenDate, chosenDoctor, chosenService, visitList]);

  useEffect(() => {
    if (scheduleByChosenDate) {
      setOpenAt(() => getTimeNumbersInArray(scheduleByChosenDate?.startAt));
      setCloseAt(() => getTimeNumbersInArray(scheduleByChosenDate?.finishAt));
    }
  }, [chosenDate, chosenClinic, scheduleByChosenDate]);

  useEffect(() => {
    if (initialValues?.doctorId) {
      setChosenDoctor(
        workerList?.find((worker) => worker._id === initialValues?.doctorId)
      );
      setChosenClinic(
        hospitalList?.find((clinic) => clinic._id === initialValues?.placeId)
      );
      setChosenDate(new Date(initialValues?.startTime as string));
    }
  }, [initialValues]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={(visitId || isVisitFromCalendar) && initialValues}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        submitFailed,
        submitErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={`${styles.select_wrap} select_wrap`}>
            <label>{t("adminPanel.form.serviceLabel")}*</label>
            <Field
              name="serviceId"
              component="select"
              validate={isRequired}
              onChange={(e: any) => {
                form.change("serviceId", e.target.value);
                const service = serviceList?.find(
                  (service) => service._id === e.target.value
                );
                setChosenService(service as IService);
              }}
            >
              <option value="" disabled>
                {t("adminPanel.form.chooseService")}
              </option>
              {(filteredServices || serviceList)?.map((service) => (
                <option value={service._id} key={service._id}>
                  {service.title}
                </option>
              ))}
            </Field>
            <Field
              name="serviceId"
              subscription={{ error: true }}
              render={({ meta }) =>
                submitFailed &&
                meta.error && <span className="error">{t(meta.error)}</span>
              }
              className="error"
            />
          </div>

          <div className={`${styles.select_wrap} select_wrap`}>
            <label>{t("adminPanel.form.clinicLabel")}*</label>
            <Field
              name="placeId"
              component="select"
              validate={isRequired}
              disabled
              onChange={(e: any) => {
                form.change("placeId", e.target.value);
                const clinic = hospitalList?.find(
                  (clinic) => clinic._id === e.target.value
                );
                setChosenClinic(clinic);
                setChosenDate(undefined);
              }}
            >
              <option value="" disabled>
                {t("adminPanel.form.chooseClinic")}
              </option>
              {hospitalList?.map((clinic) => (
                <option value={clinic._id} key={clinic._id}>
                  {clinic.title}
                </option>
              ))}
            </Field>
            <Field
              name="placeId"
              subscription={{ error: true }}
              render={({ meta }) =>
                submitFailed &&
                meta.error && <span className="error">{t(meta.error)}</span>
              }
              className="error"
            />
          </div>

          <div className={`${styles.select_wrap} select_wrap`}>
            <label>{t("adminPanel.form.chooseWorkerLabel")}*</label>
            <Field
              name="doctorId"
              component="select"
              disabled
              validate={isRequired}
              onChange={(e: any) => {
                form.change("doctorId", e.target.value);
                setChosenDoctor(
                  workerList?.find((spec) => spec._id === e.target.value)
                );
              }}
            >
              <option value="" disabled>
                {t("adminPanel.form.chooseWorker")}
              </option>
              {workerList?.map((doctor) => (
                <option value={doctor._id} key={doctor._id}>
                  {doctor.firstName} {doctor.lastName}
                </option>
              ))}
            </Field>
            <Field
              name="doctorId"
              subscription={{ error: true }}
              render={({ meta }) =>
                submitFailed &&
                meta.error && <span className="error">{t(meta.error)}</span>
              }
              className="error"
            />
          </div>

          <div className={styles.date_wrap}>
            <label>{t("adminPanel.form.dateLabel")}*</label>
            <Field
              name="startTime"
              validate={isRequired}
              component={DatePickerField}
              handleFilteredDate={handleFilteredDate}
              setChosenDate={setChosenDate}
              chosenDate={chosenDate}
              excludeTimes={excludeTimes}
              excludeDates={unscheduleDates}
              isDisabled
              minTime={getTime(openAt?.[0], openAt?.[1])}
              maxTime={getTime(closeAt?.[0] && closeAt?.[0] - 1, closeAt?.[1])}
            />
          </div>

          <button
            type="submit"
            className={styles.form_button}
            disabled={submitting}
          >
            {t("buttons.create")}
          </button>
        </form>
      )}
    />
  );
};
