// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entityTypeSelect_entity_status__ih1RF {
  height: 40px;
  padding: 0 15px;
  padding-right: 25px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/FormFields/EntityTypeSelect/entityTypeSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".entity_status {\n  height: 40px;\n  padding: 0 15px;\n  padding-right: 25px;\n  border: none;\n  outline: none;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entity_status": `entityTypeSelect_entity_status__ih1RF`
};
export default ___CSS_LOADER_EXPORT___;
