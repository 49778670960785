import React, { FC } from "react";
import { Field } from "react-final-form";
import { ITimePickerField } from "./timePickerField.interface";
import { TimePicker } from "../TimePicker/TimePicker";
import styles from "./timePickerField.module.scss";

export const TimePickerField: FC<ITimePickerField> = ({
  validate,
  minTime,
  maxTime,
  label,
  name,
  disabled,
  placeholderText,
}) => {
  return (
    <div className={styles.time_wrap}>
      <label>
        {label}
        {`${validate ? "*" : ""}`}
      </label>
      <Field
        name={name}
        validate={validate}
        component={TimePicker}
        disabled={disabled}
        minTime={minTime}
        maxTime={maxTime}
        placeholderText={placeholderText}
      />
    </div>
  );
};
