import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IEntityTypeSelect } from "./entityTypeSelect.interface";
import styles from "./entityTypeSelect.module.scss";

export const EntityTypeSelect: FC<IEntityTypeSelect> = ({
  selectValue,
  handleSelectChange,
}) => {
  const { t } = useTranslation();
  const EntityStatuses = [
    { value: "true", label: t("status.actives") },
    { value: "false", label: t("status.archives") },
  ];

  return (
    <select
      className={styles.entity_status}
      value={selectValue ? "true" : "false"}
      onChange={(e) => {
        handleSelectChange(e);
      }}
    >
      {EntityStatuses.map((status) => (
        <option key={status.value} value={status.value}>
          {status.label}
        </option>
      ))}
    </select>
  );
};
