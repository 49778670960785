import React, { FC, useEffect, useState } from "react";
import { Pagination } from "../../Pagination";
import styles from "./adminPanelTable.module.scss";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

export interface IAdminPanelTable {
  columns: ColumnDef<any, any>[];
  data: Record<string, any>[];
  currentPage: number;
  onPageChange: (arg: number) => void;
  totalCount: number;
  pageSize: number;
  isHideActions?: boolean;
  isHidePayment?: boolean;
  isHideAttached?: boolean;
}

export const AdminPanelTable: FC<IAdminPanelTable> = ({
  columns,
  data,
  currentPage,
  onPageChange,
  totalCount,
  pageSize,
  isHideActions,
  isHidePayment,
  isHideAttached = false,
}) => {
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    if (isHideActions) {
      setColumnVisibility((prev) => ({
        ...prev,
        actions: false,
      }));
    }
    if (isHidePayment) {
      setColumnVisibility((prev) => ({
        ...prev,
        isPaid: false,
      }));
    } else {
      setColumnVisibility((prev) => ({
        ...prev,
        isPaid: true,
      }));
    }
    if (isHideAttached) {
      setColumnVisibility((prev) => ({
        ...prev,
        attached: false,
      }));
    }
  }, [isHideActions, isHidePayment]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <div className={`${styles.table_wrap} custom_wrap`}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`${
                  row.original.hasOwnProperty("isActive") &&
                  !row.original.isActive &&
                  styles.inactive_row
                }`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <div>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className={styles.pagination_bar}
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </>
  );
};
