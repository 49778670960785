import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ExcludeTimeState {
  excludeTimes: Date[]; // Assuming excludeTimes is an array of strings, adjust the type if necessary
}
const initialState: ExcludeTimeState = {
  excludeTimes: [],
};

export const excludeTimeSlice = createSlice({
  name: "excludeTimeReducer",
  initialState,
  reducers: {
    setExcludeTimes: (state, action: PayloadAction<Date[]>) => {
      const uniqueTimes = new Set([...state.excludeTimes, ...action.payload]);
      state.excludeTimes = Array.from(uniqueTimes);
    },
    resetExcludeTimes: (state) => {
      state.excludeTimes = [];
    },
  },
});

export const { setExcludeTimes, resetExcludeTimes } = excludeTimeSlice.actions;

export default excludeTimeSlice.reducer;
