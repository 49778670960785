import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks";
import { placeQueryData, userRoles } from "../../../shared";
import { placeApi } from "../../../store/services";
import styles from "./selectHospital.module.scss";

export interface ISelectHospital {
  selectValue: string;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectHospital: FC<ISelectHospital> = ({
  selectValue,
  handleSelectChange,
}) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );
  const { data: hospitals } = placeApi.useFetchPlaceListQuery(placeQueryData, {
    skip: !currentUser,
  });

  return (
    <div className={styles.select_hospital_wrap}>
      {currentUser?.role !== userRoles.rehabilitator ? (
        <select value={selectValue || ""} onChange={handleSelectChange}>
          <option value="">{t("adminPanel.form.allHospitals")}</option>
          {(hospitals?.items || []).map((place) => (
            <option key={place._id} value={place._id}>
              {place.title}
            </option>
          ))}
        </select>
      ) : (
        <p className={styles.hospital_title}>
          {currentUser.portfolio?.places[0].title}
        </p>
      )}
    </div>
  );
};
