import React, { FC, forwardRef, LegacyRef } from "react";
import DatePicker from "react-datepicker";
import { IDatePickerField } from "./datePickerField.interface";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePickerField.module.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const DatePickerField: FC<IDatePickerField> = ({
  name,
  meta,
  input,
  input: { value, onChange },
  minTime,
  maxTime,
  minDate,
  handleFilteredDate,
  setChosenDate,
  chosenDate,
  excludeTimes,
  excludeDates,
  showTimeSelect = true,
  isDisabled = false,
  placeholderText = "adminPanel.form.chooseDateTime",
}) => {
  const { t } = useTranslation();

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }: any, ref) => (
      <button
        className={className}
        onClick={onClick}
        ref={ref as LegacyRef<HTMLButtonElement>}
        type="button"
      >
        {value || t(placeholderText)}
      </button>
    )
  );

  const onDateChange = (date: string | Date) => {
    const newDate = moment(date).toISOString(true);
    onChange(newDate);
    setChosenDate(newDate);
  };

  return (
    <>
      <DatePicker
        placeholderText={t(placeholderText)}
        dateFormat={showTimeSelect ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
        timeFormat="HH:mm"
        timeIntervals={60}
        showTimeSelect={showTimeSelect}
        customInput={<ExampleCustomInput className="datepicker_field" />}
        selected={chosenDate ? moment(chosenDate).toDate() : undefined}
        disabledKeyboardNavigation
        name={name}
        onChange={(date) => onDateChange(date as Date)}
        filterDate={(date) => {
          if (handleFilteredDate) {
            return handleFilteredDate(date);
          }
          return true;
        }}
        minDate={minDate || new Date()}
        maxDate={moment().add(2, "month").toDate()}
        minTime={minTime}
        maxTime={maxTime}
        excludeTimes={excludeTimes}
        excludeDates={excludeDates}
        onFocus={(e) => e.target.blur()}
        disabled={isDisabled}
      />
      {meta.touched && meta.error && (
        <span className="error">{t(meta.error)}</span>
      )}
    </>
  );
};
