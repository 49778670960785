export enum SortingFields {
  firstName = "firstName",
  doctor = "medicalCard.doctor.firstName",
  patientPlace = "medicalCard.place.title",
  doctorPlace = "portfolio.place.title",
  startTime = "startTime",
  createdAt = "createdAt",
  title = "title",
  isPublished = "isPublished",
  expiredAt = "expiredAt",
}
