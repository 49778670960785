import { ReactComponent as Users } from "../../../../assets/icons/admin_panel_users.svg";
import { ReactComponent as Patients } from "../../../../assets/icons/admin_panel_patients.svg";
import { ReactComponent as Me } from "../../../../assets/icons/admin_panel_doctors.svg";
import { ReactComponent as Rehabilitator } from "../../../../assets/icons/rehabilitator.svg";
import { ReactComponent as Content } from "../../../../assets/icons/content.svg";
import { ReactComponent as Services } from "../../../../assets/icons/admin_panel_pages.svg";
import { ReactComponent as News } from "../../../../assets/icons/admin_panel_news.svg";
import { ReactComponent as Reviews } from "../../../../assets/icons/admin_panel_reviews.svg";
import { ReactComponent as Media } from "../../../../assets/icons/admin_panel_media.svg";
import { ReactComponent as Settings } from "../../../../assets/icons/admin_panel_settings .svg";
import { ReactComponent as Doctors } from "../../../../assets/icons/doctor_small_icon.svg";
import { ReactComponent as Visits } from "../../../../assets/icons/visits.svg";
import { ReactComponent as DailyVisits } from "../../../../assets/icons/daily_visits.svg";
import { ReactComponent as VisitsHistory } from "../../../../assets/icons/visits_history.svg";
import { ReactComponent as VisitsCalendar } from "../../../../assets/icons/visits_calendar.svg";
import { ReactComponent as Hospitals } from "../../../../assets/icons/hospital.svg";
import { ReactComponent as Table } from "../../../../assets/icons/table.svg";
import { ReactComponent as Schedule } from "../../../../assets/icons/schedule.svg";
import { ReactComponent as Gift } from "../../../../assets/icons/gift-svg.svg";

import { RoutesEnum } from "../../helpers";

export const adminMenuData = [
  {
    title: "adminMenu.users",
    TitleIcon: Users,
    items: [
      {
        item: "adminMenu.patients",
        itemIcon: Patients,
        url: `${RoutesEnum.admin}${RoutesEnum.patients}`,
        isActive: false,
      },
      {
        item: "adminMenu.doctors",
        itemIcon: Doctors,
        url: `${RoutesEnum.admin}${RoutesEnum.doctors}`,
      },
      {
        item: "adminMenu.rehabilitators",
        itemIcon: Rehabilitator,
        url: `${RoutesEnum.admin}${RoutesEnum.rehabilitators}`,
      },
      {
        item: "adminMenu.me",
        itemIcon: Me,
        url: `${RoutesEnum.admin}${RoutesEnum.profile}`,
      },
    ],
  },
  {
    title: "adminMenu.visits",
    TitleIcon: Visits,
    items: [
      {
        item: "adminMenu.dailyExercises",
        itemIcon: DailyVisits,
        url: `${RoutesEnum.admin}${RoutesEnum.dailyVisits}`,
      },
      {
        item: "adminMenu.visitsHistory",
        itemIcon: VisitsHistory,
        url: `${RoutesEnum.admin}${RoutesEnum.visitsHistory}`,
      },
      {
        item: "adminMenu.visitsCalendar",
        itemIcon: VisitsCalendar,
        url: `${RoutesEnum.admin}${RoutesEnum.visitsCalendar}`,
      },
      {
        item: "adminMenu.certificates",
        itemIcon: Gift,
        url: `${RoutesEnum.admin}${RoutesEnum.giftCertificate}`,
      },
    ],
  },
  {
    title: "adminMenu.content",
    TitleIcon: Content,
    items: [
      {
        item: "adminMenu.services",
        itemIcon: Services,
        url: `${RoutesEnum.admin}${RoutesEnum.services}`,
      },

      {
        item: "adminMenu.news",
        itemIcon: News,
        url: `${RoutesEnum.admin}${RoutesEnum.articles}`,
      },
      {
        item: "adminMenu.reviews",
        itemIcon: Reviews,
        url: `${RoutesEnum.admin}${RoutesEnum.reviews}`,
      },
    ],
  },
  {
    title: "adminMenu.administrate",
    TitleIcon: Settings,
    items: [
      {
        item: "adminMenu.hospitalsData",
        itemIcon: Hospitals,
        url: `${RoutesEnum.admin}${RoutesEnum.hospitals}`,
      },
      {
        item: "adminMenu.schedules",
        itemIcon: Schedule,
        url: `${RoutesEnum.admin}${RoutesEnum.schedules}`,
      },
    ],
  },
];

export const patientMenuData = [
  {
    title: "adminMenu.me",
    TitleIcon: Users,
    items: [
      {
        item: "adminMenu.profile",
        itemIcon: Me,
        url: `${RoutesEnum.admin}${RoutesEnum.profile}`,
      },
      {
        item: "adminMenu.gallery",
        itemIcon: Media,
        url: `${RoutesEnum.admin}${RoutesEnum.gallery}`,
      },
      {
        item: "adminMenu.certificates",
        itemIcon: Gift,
        url: `${RoutesEnum.admin}${RoutesEnum.giftCertificate}`,
      },
    ],
  },
  {
    title: "adminMenu.visits",
    TitleIcon: Visits,
    items: [
      {
        item: "adminMenu.exercisesPerDay",
        itemIcon: DailyVisits,
        url: `${RoutesEnum.admin}${RoutesEnum.dailyVisits}`,
      },
      {
        item: "adminMenu.exercisesTable",
        itemIcon: Table,
        url: `${RoutesEnum.admin}${RoutesEnum.myTable}`,
      },
      {
        item: "adminMenu.visitsHistory",
        itemIcon: VisitsHistory,
        url: `${RoutesEnum.admin}${RoutesEnum.visitsHistory}`,
      },
      {
        item: "adminMenu.newVisit",
        itemIcon: Schedule,
        url: `${RoutesEnum.admin}${RoutesEnum.newVisit}`,
      },
    ],
  },
];
