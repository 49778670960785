import home_hero_img from "./home_hero.webp";
import neurosurgical_consultations from "./neurosurgical_consultations.png";
import news_01 from "./news_01.png";
import about_us from "./about_us.webp";
import service_hero from "./service_hero.webp";
import illustration_article from "./illustration_article.webp";
import about_us_hero from "./about_us_hero.webp";

import contacts_hero from "./contacts_hero.webp";
import doctors_hero from "./doctors_hero.webp";
import doctor_01_details from "./doctor_01_details.png";
import reviews_hero from "./reviews_hero.webp";

import doctor_default from "./doctor_default.png";

export {
  home_hero_img,
  neurosurgical_consultations,
  news_01,
  about_us,
  service_hero,
  illustration_article,
  about_us_hero,
  contacts_hero,
  doctors_hero,
  doctor_01_details,
  reviews_hero,
  doctor_default,
};
