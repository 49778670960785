export enum ReducerKeys {
  REVIEWS_API_REDUCER_KEY = "reviewsApi",
  SERVICES_API_REDUCER_KEY = "servicesApi",
  ARTICLES_API_REDUCER_KEY = "articlesApi",
  PLACES_API_REDUCER_KEY = "placesApi",
  USERS_API_REDUCER_KEY = "usersProfileApi",
  SCHEDULE_API_REDUCER_KEY = "scheduleApi",
  EXERCISES_API_REDUCER_KEY = "exercisesApi",
  VISITS_API_REDUCER_KEY = "visitsApi",
  FILES_API_REDUCER_KEY = "filesApi",
  CONTACT_US_API_REDUCER_KEY = "contactUsApi",
  PAYMENTS_API_REDUCER_KEY = "payments",
  VOUCHERS_API_REDUCER_KEY = "vouchersApi",
}
