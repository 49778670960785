export const voucherApi = {
  createPublicVoucher: (): string => `/api/public/vouchers`,
  createPublicVoucherPayment: (id: string): string =>
    `/api/public/vouchers/${id}/stripe/intent`,
  fetchVouchersByAdmin: (): string => `/api/admin/vouchers`,
  fetchVoucherDetailsByAdmin: (id: string): string =>
    `/api/admin/vouchers/${id}`,
  markAsPaid: (id: string): string => `/api/admin/vouchers/${id}/mark-as-paid`,
  fetchVouchersByUser: (): string => `/api/portal/patient/vouchers`,
  fetchVoucherDetailsByUser: (id: string): string =>
    `/api/portal/patient/vouchers/${id}`,
  addVoucherToUser: (id: string): string =>
    `/api/portal/patient/vouchers/${id}/add`,
  validateVoucher: (visitId: string, voucherKey: string): string =>
    `/api/public/visits/${visitId}/vouchers/${voucherKey}/check`,
};
