import React, { FC } from "react";
import styles from "./addButton.module.scss";
import { useTranslation } from "react-i18next";
import { IAddButton } from "./addButton.interface";

export const AddButton: FC<IAddButton> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      title={t("buttons.add")}
      className={styles.add_button}
      onClick={onClick}
      disabled={disabled}
    >
      +
    </button>
  );
};
