// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navMenu_nav_menu__piVjF {
  display: flex;
  align-items: center;
}
.navMenu_nav_menu__piVjF ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  height: 100%;
}
.navMenu_nav_menu__piVjF ul li {
  margin-right: 50px;
}
.navMenu_nav_menu__piVjF ul a {
  display: block;
  width: max-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s;
}
.navMenu_nav_menu__piVjF ul a:hover {
  color: #ff6138 !important;
  transition: all 0.4s;
}
@media (max-width: 1440px) {
  .navMenu_nav_menu__piVjF ul li {
    margin-right: 30px;
  }
}
@media (max-width: 1199px) {
  .navMenu_nav_menu__piVjF ul {
    padding-left: 0px;
  }
  .navMenu_nav_menu__piVjF ul li {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .navMenu_nav_menu__piVjF ul {
    padding-left: 0px;
  }
  .navMenu_nav_menu__piVjF ul li {
    margin-right: 17px;
  }
  .navMenu_nav_menu__piVjF ul li a {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .navMenu_nav_menu__piVjF ul li {
    margin-right: 15px;
  }
  .navMenu_nav_menu__piVjF ul li a {
    font-size: 12px;
    line-height: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/NavMenu/navMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,YAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;AAAN;AAEM;EACE,yBAAA;EACA,oBAAA;AAAR;AAKE;EAEI;IACE,kBAAA;EAJN;AACF;AAQE;EACE;IACE,iBAAA;EANJ;EAQI;IACE,kBAAA;EANN;AACF;AAUE;EACE;IACE,iBAAA;EARJ;EAUI;IACE,kBAAA;EARN;EAUM;IACE,eAAA;EARR;AACF;AAaE;EAEI;IACE,kBAAA;EAZN;EAcM;IACE,eAAA;IACA,iBAAA;EAZR;AACF","sourcesContent":[".nav_menu {\n  display: flex;\n  align-items: center;\n\n  ul {\n    display: flex;\n    align-items: center;\n    list-style-type: none;\n    height: 100%;\n\n    li {\n      margin-right: 50px;\n    }\n\n    a {\n      display: block;\n      width: max-content;\n      font-weight: 700;\n      font-size: 16px;\n      line-height: 18px;\n      color: #000000;\n      text-decoration: none;\n      text-transform: uppercase;\n      transition: color 0.4s;\n\n      &:hover {\n        color: #ff6138 !important;\n        transition: all 0.4s;\n      }\n    }\n  }\n\n  @media (max-width: 1440px) {\n    ul {\n      li {\n        margin-right: 30px;\n      }\n    }\n  }\n\n  @media (max-width: 1199px) {\n    ul {\n      padding-left: 0px;\n\n      li {\n        margin-right: 20px;\n      }\n    }\n  }\n\n  @media (max-width: 991px) {\n    ul {\n      padding-left: 0px;\n\n      li {\n        margin-right: 17px;\n\n        a {\n          font-size: 12px;\n        }\n      }\n    }\n  }\n\n  @media (max-width: 767px) {\n    ul {\n      li {\n        margin-right: 15px;\n\n        a {\n          font-size: 12px;\n          line-height: 14px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_menu": `navMenu_nav_menu__piVjF`
};
export default ___CSS_LOADER_EXPORT___;
