// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableActivateButton_table_activate_button__v4Ubs {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  padding-top: 1px;
  transition: all 0.2s ease-in-out;
  width: 20px;
}
.tableActivateButton_table_activate_button__v4Ubs img {
  width: 100%;
}
.tableActivateButton_table_activate_button__v4Ubs:hover {
  transform: scale(0.93);
  transition: all 0.2s ease-in-out;
}
.tableActivateButton_table_activate_button__v4Ubs:active {
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .tableActivateButton_table_activate_button__v4Ubs {
    width: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/Buttons/TableActivateButton/tableActivateButton.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,gBAAA;EACA,gCAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,sBAAA;EACA,gCAAA;AAAJ;AAGE;EACE,qBAAA;EACA,gCAAA;AADJ;AAIE;EAvBF;IAwBI,WAAA;EADF;AACF","sourcesContent":[".table_activate_button {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  padding-top: 1px;\n  transition: all 0.2s ease-in-out;\n  width: 20px;\n\n  img {\n    width: 100%;\n  }\n\n  &:hover {\n    transform: scale(0.93);\n    transition: all 0.2s ease-in-out;\n  }\n\n  &:active {\n    transform: scale(0.9);\n    transition: all 0.2s ease-in-out;\n  }\n\n  @media (max-width: 991px) {\n    width: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_activate_button": `tableActivateButton_table_activate_button__v4Ubs`
};
export default ___CSS_LOADER_EXPORT___;
