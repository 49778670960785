export const paymentApi = {
  createPayment: (id: string): string =>
    `/api/public/visits/${id}/stripe/intent`,
  markAsPaid: (id: string) => `/api/portal/staff/visits/${id}/mark-as-paid`,
  refundPayment: (id: string): string => `/api/portal/visits/${id}/refund`,
  approveRefund: (id: string): string =>
    `/api/portal/staff/visits/${id}/approve-refund`,
  payWithVoucherByAdmin: (id: string): string =>
    `/api/portal/staff/visits/${id}/pay-with-voucher`,
  payWithVoucherByUser: (id: string): string =>
    `/api/public/visits/${id}/pay-with-voucher`,
};
