import { stageApi } from "./stageApi";
import { dailyExerciseApi } from "./dailyExerciseApi";
import { reviewApi } from "./reviewApi";
import { serviceApi } from "./serviceApi";
import { userApi } from "./userApi";
import { placeApi } from "./placeApi";
import { authApi } from "./authApi";
import { articleApi } from "./articleApi";
import { visitApi } from "./visitApi";
import { exerciseApi } from "./exerciseApi";
import { medicalCardApi } from "./medicalCardApi";
import { scheduleApi } from "./scheduleApi";
import { filesApi } from "./filesApi";
import { contactUsApi } from "./contactUsApi";
import { paymentApi } from "./paymentApi";
import { voucherApi } from "./voucherApi";

export const PathBuilder = {
  authApi,
  placeApi,
  userApi,
  serviceApi,
  articleApi,
  reviewApi,
  visitApi,
  exerciseApi,
  dailyExerciseApi,
  medicalCardApi,
  stageApi,
  scheduleApi,
  filesApi,
  contactUsApi,
  paymentApi,
  voucherApi,
};
