// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/icons/arrow-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  max-width: 1620px;
  min-width: 690px;
  margin: 0 auto;
  background-color: #ffffff;
}

.fade_in {
  animation: fadeIn 0.3s ease-in forwards;
}

select {
  appearance: none;
  color: black;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 98% 50%;
  background-size: 18px;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EAGE,gBAAgB;EAChB,YAAY;EACZ,qEAAqE;EACrE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".App {\n  max-width: 1620px;\n  min-width: 690px;\n  margin: 0 auto;\n  background-color: #ffffff;\n}\n\n.fade_in {\n  animation: fadeIn 0.3s ease-in forwards;\n}\n\nselect {\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  appearance: none;\n  color: black;\n  background: url(\"/src/assets/icons/arrow-down.svg\") no-repeat 98% 50%;\n  background-size: 18px;\n  background-color: #ffffff;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
