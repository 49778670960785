import { FC } from "react";
import styles from "./selectField.module.scss";

export interface ISelectField {
  options: { id: string; title: string }[];
  selectedValue: string;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectField: FC<ISelectField> = ({
  options,
  selectedValue,
  handleSelectChange,
}) => {
  return (
    <div className={styles.select_wrap}>
      <select value={selectedValue || ""} onChange={handleSelectChange}>
        {(options || []).map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};
