import { CustomLink } from "../../CustomLink";

import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../shared";
import { logo } from "../../../../assets";

import styles from "./paymentForm.module.scss";

export const SucceededPayment = ({ hideRedirect = false }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.paymentSuccessfulWrap} ${styles.fade_in}`}>
      <CustomLink to="/" customStyle={styles.logo_link}>
        <img src={logo} alt="Logo" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="48px"
          height="48px"
        >
          <path
            fill="#38b47b"
            d="M42.98,19.77c-0.28-1.22-0.84-2.36-1.39-3.49c-0.65-1.34-1.02-2.62-1.67-3.97 c-0.53-1.08-1.07-2.18-1.82-3.12c-2.48-3.12-6.71-4.23-10.62-4.94c-2.34-0.42-4.72-0.85-7.08-0.54C18.88,3.9,17.42,4.4,16,4.97 c-2.28,0.91-4.24,2.2-5.98,3.93c-5.68,5.3-7.91,13.82-5.69,21.26c0.4,1.31,0.92,2.59,1.48,3.84c0.49,1.09,1.02,2.18,1.69,3.17 c1.93,2.84,5.12,4.3,8.27,5.66c1.22,0.52,2.47,0.98,3.77,1.27c3.86,0.88,7.98,0.26,11.62-1.31c5.35-2.31,9.91-6.37,11.49-11.98 c0.65-2.31,0.52-4.8,0.58-7.2C43.26,22.33,43.27,21.02,42.98,19.77z M35.19,34.66c-4.39,4.44-11.44,6.03-17.31,3.89 S8,30.91,7.5,24.69c-0.36-4.45,0.88-9.12,4.43-11.81c2.94-2.32,6.26-4.31,9.97-4.74c2.25-0.26,4.52,0.05,6.75,0.37 c2.53,0.35,5.28,0.82,6.96,2.74c0.71,0.82,1.16,1.84,1.62,2.82c0.56,1.17,1.16,2.33,1.62,3.55 C41.06,23.46,39.58,30.21,35.19,34.66z"
          />
          <path
            fill="#010101"
            d="M23.172,44.996c-1.284,0-2.541-0.135-3.745-0.407c-1.186-0.268-2.446-0.693-3.854-1.301 c-3.497-1.506-6.55-2.984-8.486-5.839c-0.715-1.055-1.255-2.184-1.73-3.246c-0.689-1.535-1.152-2.739-1.5-3.904 c-2.281-7.632,0.056-16.375,5.815-21.76c1.779-1.77,3.79-3.09,6.146-4.036c1.211-0.486,2.818-1.073,4.521-1.294 c2.455-0.315,4.883,0.123,7.231,0.549c3.959,0.719,8.353,1.889,10.923,5.117c0.791,0.995,1.342,2.126,1.875,3.22 c0.338,0.691,0.602,1.369,0.856,2.024c0.246,0.635,0.501,1.29,0.818,1.942c0.554,1.134,1.127,2.308,1.426,3.596 c0.303,1.315,0.297,2.682,0.265,3.969c-0.013,0.478-0.017,0.959-0.021,1.44c-0.018,1.948-0.036,3.964-0.575,5.876 c-1.497,5.305-5.679,9.674-11.774,12.303C28.676,44.403,25.865,44.996,23.172,44.996z M22.08,4.102c-0.54,0-1.078,0.03-1.614,0.1 c-1.591,0.206-3.121,0.765-4.276,1.229c-2.231,0.896-4.135,2.146-5.819,3.823c-0.004,0.004-0.008,0.007-0.011,0.011 c-5.493,5.131-7.722,13.469-5.545,20.748c0.334,1.121,0.782,2.287,1.453,3.781c0.458,1.021,0.974,2.104,1.646,3.095 c1.784,2.63,4.703,4.038,8.054,5.481c1.352,0.582,2.556,0.989,3.68,1.244c3.526,0.797,7.545,0.34,11.316-1.287 c5.814-2.508,9.795-6.647,11.208-11.656c0.503-1.784,0.521-3.73,0.538-5.613c0.005-0.488,0.009-0.975,0.021-1.457 c0.032-1.273,0.038-2.517-0.239-3.718c-0.272-1.178-0.82-2.299-1.35-3.383c-0.336-0.69-0.599-1.365-0.853-2.019 c-0.247-0.636-0.503-1.294-0.822-1.949c-0.51-1.045-1.036-2.125-1.76-3.034c-2.363-2.97-6.542-4.071-10.317-4.756 C25.646,4.426,23.856,4.102,22.08,4.102z M10.018,8.899h0.01H10.018z"
          />
          <path
            fill="#38b47b"
            d="M21.5,26.5c2.44-3.086,4.285-5.329,6.725-8.415c1.169-1.479,2.74-2.491,4.275-3.585 c-0.38,1.437-2.127,4.797-3,6c-1.471,2.027-1.802,2.801-3,5c-1.804,3.311-3.122,5.341-4.813,8.711 c-0.984-2.506-3.579-4.717-5.158-6.898c-1.377-1.903-4.085-5.852-4.029-5.813C15.223,23.422,18.919,24.391,21.5,26.5z"
          />
          <path
            fill="#010101"
            d="M21.687,34.711c-0.008,0-0.015,0-0.022-0.001c-0.197-0.009-0.371-0.133-0.443-0.316 c-0.61-1.557-1.912-3.034-3.171-4.464c-0.702-0.796-1.365-1.549-1.926-2.323c-4.379-6.054-4.318-6.115-3.98-6.458 c0.171-0.173,0.445-0.197,0.645-0.057c1.213,0.855,2.672,1.539,4.083,2.2c1.564,0.733,3.179,1.489,4.545,2.509 c1.095-1.382,2.075-2.602,3.057-3.821c1.07-1.332,2.141-2.663,3.359-4.205c1.068-1.351,2.46-2.33,3.806-3.277l0.572-0.404 c0.172-0.122,0.402-0.125,0.574-0.004c0.174,0.12,0.253,0.335,0.199,0.539c-0.412,1.56-2.222,4.984-3.079,6.166 c-1.187,1.636-1.614,2.433-2.391,3.88l-0.574,1.065c-0.701,1.286-1.328,2.379-1.933,3.433c-0.948,1.651-1.844,3.211-2.873,5.264 C22.048,34.604,21.875,34.711,21.687,34.711z M14.182,23.094c0.862,1.253,1.987,2.868,2.753,3.927 c0.532,0.735,1.18,1.471,1.865,2.248c1.069,1.214,2.167,2.461,2.904,3.808c0.853-1.645,1.625-2.99,2.436-4.402 c0.601-1.047,1.225-2.134,1.921-3.413l0.572-1.06c0.797-1.486,1.236-2.305,2.463-3.995c0.634-0.873,1.693-2.832,2.365-4.358 c-1.063,0.765-2.051,1.543-2.844,2.548c-1.221,1.544-2.293,2.877-3.365,4.21c-1.07,1.332-2.141,2.663-3.359,4.205 c-0.083,0.104-0.205,0.172-0.339,0.187c-0.133,0.017-0.267-0.024-0.37-0.11c-1.35-1.103-3.071-1.909-4.736-2.689 C15.693,23.845,14.926,23.484,14.182,23.094z"
          />
          <path
            fill="#010101"
            d="M23.28,39.979c-1.901,0-3.795-0.313-5.571-0.96C11.727,36.839,7.524,31.23,7.002,24.73 c-0.424-5.234,1.262-9.699,4.626-12.249c3.7-2.92,6.948-4.459,10.215-4.839c2.37-0.273,4.766,0.069,6.879,0.372 c2.83,0.393,5.518,0.91,7.265,2.906c0.702,0.811,1.153,1.774,1.589,2.707l0.461,0.96c0.447,0.92,0.91,1.87,1.281,2.855 c2.283,6.034,0.804,12.931-3.771,17.568c-0.001,0-0.001,0-0.001,0C32.379,38.215,27.805,39.979,23.28,39.979z M23.667,8.543 c-0.571,0-1.143,0.028-1.709,0.094c-3.074,0.356-6.163,1.83-9.719,4.636c-3.094,2.345-4.637,6.489-4.241,11.378 c0.491,6.111,4.438,11.383,10.053,13.43c5.647,2.058,12.549,0.51,16.783-3.771l0,0c4.302-4.359,5.693-10.841,3.548-16.512 c-0.355-0.942-0.808-1.872-1.245-2.771l-0.467-0.974c-0.407-0.87-0.828-1.771-1.438-2.475c-1.519-1.734-4.013-2.206-6.65-2.572 C27.046,8.785,25.357,8.543,23.667,8.543z M35.19,34.66h0.01H35.19z"
          />
        </svg>
      </CustomLink>
      <h2>{t("paymentSuccessful")}</h2>

      {!hideRedirect && (
        <CustomLink
          to={`${RoutesEnum.admin}${RoutesEnum.visitsHistory}`}
          customStyle={styles.back_link}
          className="green_outlined"
        >
          {t("buttons.backToVisits")}
        </CustomLink>
      )}
    </div>
  );
};
