import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { ISearchUserInput } from "./searchUserInput.interface";
import styles from "./searchUserInput.module.scss";
import { searchIcon } from "@jat-med/assets";

export const SearchUserInput: FC<ISearchUserInput> = ({
  debouncedValue,
  users,
  onSelectUser,
  onSearch,
  onSearchByButton,
  isShowTitle = false,
  isShowButton = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.find_item}>
      {isShowTitle && <h3>{t("adminPanel.form.registeredPatient")}</h3>}

      <input
        id="search"
        type="text"
        placeholder={t("adminPanel.form.search")}
        onChange={(e) => {
          onSearch && onSearch(e);
        }}
      />
      {debouncedValue && (
        <ul>
          {(users || []).map((user) => (
            <li
              key={user._id}
              onClick={() => {
                onSelectUser && onSelectUser(user);
              }}
            >
              {user.firstName} {user.lastName}
            </li>
          ))}
        </ul>
      )}
      {isShowButton && (
        <button
          type="button"
          disabled={isDisabled}
          onClick={() => {
            onSearchByButton && onSearchByButton();
          }}
        >
          <img src={searchIcon} alt="Search Icon" />
        </button>
      )}
    </div>
  );
};
