import React, { FC, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ITimePicker } from "./timePicker.interface";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./timePicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";

export const TimePicker: FC<ITimePicker> = ({
  minTime,
  maxTime,
  disabled,
  placeholderText,
  input: { value, onChange },
  meta,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | null>(value as Date);

  const onDateChange = (date: null | Date) => {
    const newTime = moment(date);

    setStartDate(date);
    onChange(newTime);
  };

  return (
    <>
      <ReactDatePicker
        selected={
          moment(startDate).format("HH:mm") === "00:00" ? null : startDate
        }
        onChange={(date) => onDateChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeCaption="Time"
        dateFormat="HH:mm "
        timeFormat="HH:mm"
        minTime={minTime}
        maxTime={maxTime}
        disabled={disabled}
        placeholderText={placeholderText}
        disabledKeyboardNavigation
        onFocus={(e) => e.target.blur()}
      />

      {!disabled && meta.touched && meta.error && (
        <span className={styles.error_message}>{t(meta.error)}</span>
      )}
    </>
  );
};
