import { Field } from "react-final-form";
import { IPlace, IService, isRequired } from "../../../shared";
import styles from "./customSelectField.module.scss";
import { useTranslation } from "react-i18next";
import { ICustomSelectField } from "./customSelectField.interface";

export function CustomSelectField<T extends IService | IPlace>({
  setSelectedItems,
  selectedItems,
  propItems,
  fieldName,
  fieldLabel,
  fieldOption,
  isChooseOne = false,
}: ICustomSelectField<T>) {
  const { t } = useTranslation();

  return (
    <Field name={fieldName} validate={isRequired}>
      {({ input, meta }) => (
        <div
          className={`${styles.select_wrap} ${styles.services_wrap} select_wrap`}
        >
          <label>{t(fieldLabel)}</label>
          <select
            {...input}
            onChange={(e) => {
              const set = new Set([...selectedItems, e.target.value]);
              if (set.size !== selectedItems.length && !isChooseOne) {
                setSelectedItems([...selectedItems, e.target.value]);
              } else {
                setSelectedItems([e.target.value]);
              }

              input.onChange(e.target.value);
            }}
            value={selectedItems[0] || ""}
          >
            <option value="" disabled>
              {t(fieldOption)}
            </option>
            {propItems.map((option) => (
              <option key={option._id} value={option._id}>
                {option.title}
              </option>
            ))}
          </select>
          <Field
            name={fieldName}
            subscription={{ error: true }}
            render={({ meta }) =>
              meta.error && (
                <span className={styles.error_message}>{t(meta.error)}</span>
              )
            }
            className={styles.error_message}
          />
          <div>
            {!!selectedItems.length &&
              propItems &&
              selectedItems.map((item, index) => {
                const selectedItem = propItems.find((el) => {
                  return el._id === item;
                });

                return (
                  <div key={item} className={styles.service_item}>
                    {selectedItem && (
                      <>
                        <span>{selectedItem.title}</span>
                        <div
                          className={styles.delete_button}
                          onClick={() => {
                            setSelectedItems(
                              selectedItems.filter((el) => el !== item)
                            );

                            if (selectedItems.length === 1) {
                              input.onChange("");
                            }
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Field>
  );
}
