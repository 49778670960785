import { useTranslation } from "react-i18next";
import { isCorrectVoucherCode, userRoles } from "../../../shared";
import {
  credit_card_payment,
  gift,
  money_cash,
  searchIcon,
  stripe,
} from "@jat-med/assets";
import { paymentType, PaymentTypesEnum } from "../../../shared/types/payment";

import styles from "./paymentOptions.module.scss";
import { useState } from "react";
import { Button } from "../../Buttons";

interface PaymentOptionsProps {
  currentUser: any;
  setIsStripePayment: (value: boolean) => void;
  onHandleMarkAsPaid: (paymentType: paymentType) => void;
  onHandleVoucherPayment?: (voucherCode: string) => void;
  onValidateVoucher?: (voucherCode: string) => Promise<boolean>;
}

export const PaymentOptions = ({
  currentUser,
  setIsStripePayment,
  onHandleMarkAsPaid,
  onHandleVoucherPayment,
  onValidateVoucher,
}: PaymentOptionsProps) => {
  const { t } = useTranslation();
  const isPatient = currentUser?.role === userRoles.patient;
  const isAdmin = currentUser?.role === userRoles.admin;
  const [isVoucherPaymentActive, setIsVoucherPaymentActive] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [isValidVoucherCode, setIsValidVoucherCode] = useState(false);

  return (
    <div className={styles.payment_container}>
      <div>
        <h2>{t("adminPanel.online")}</h2>
        <button
          className={styles.payment_button}
          onClick={() => setIsStripePayment(true)}
        >
          <img src={stripe} alt={t("buttons.paymentMethodStripe")} />
          {t("buttons.paymentMethodStripe")}
        </button>
        {onHandleVoucherPayment && (
          <div className={styles.voucher_payment_wrapper}>
            <button
              className={styles.payment_button}
              onClick={() => setIsVoucherPaymentActive(true)}
            >
              <img src={gift} alt={t("buttons.paymentMethodVoucher")} />
              {t("buttons.paymentMethodVoucher")}
            </button>
            {isVoucherPaymentActive && (
              <>
                <div className={styles.search_wrapper}>
                  <input
                    type="text"
                    placeholder={t("voucherCode")}
                    maxLength={8}
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value)}
                  />
                  <button
                    type="button"
                    disabled={!isCorrectVoucherCode(voucherCode)}
                    onClick={async () => {
                      const result =
                        onValidateVoucher &&
                        (await onValidateVoucher(voucherCode));

                      if (result) {
                        setIsValidVoucherCode(true);
                      }
                    }}
                  >
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                </div>
                <Button
                  disabled={
                    !isValidVoucherCode || !isCorrectVoucherCode(voucherCode)
                  }
                  onClick={() => {
                    onHandleVoucherPayment(voucherCode);
                    setIsValidVoucherCode(false);
                    setVoucherCode("");
                  }}
                  className="green_outlined"
                  customStyle={styles.voucher_payment_button}
                  label={t("buttons.pay")}
                />
              </>
            )}
          </div>
        )}
      </div>
      <div>
        <h2>
          {t("adminPanel.cashDesk")}{" "}
          {(isPatient || !currentUser || !isAdmin) && (
            <span>{t("availableAtHospital")}</span>
          )}
        </h2>
        <button
          disabled={isPatient || !currentUser || !isAdmin}
          className={`${styles.payment_button} ${
            (isPatient || !currentUser || !isAdmin) && styles.disabled
          }`}
          onClick={() => onHandleMarkAsPaid(PaymentTypesEnum.CASH)}
        >
          <img src={money_cash} alt="" />
          {t("buttons.paymentMethodCash")}
        </button>
        <button
          disabled={isPatient || !currentUser || !isAdmin}
          className={`${styles.payment_button} ${
            (isPatient || !currentUser || !isAdmin) && styles.disabled
          }`}
          onClick={() => onHandleMarkAsPaid(PaymentTypesEnum.CREDIT_CARD)}
        >
          <img src={credit_card_payment} alt="" />
          {t("buttons.paymentMethodCard")}
        </button>
      </div>
    </div>
  );
};
