export interface IPaymentResponse {
  id: string;
  object: string;
  amount: number;
  amount_capturable: number;
  amount_received: number;
  client_secret: string;
  currency: string;
  capture_method: string;
  confirmation_method: string;
  created: number;
  livemode: boolean;
  status: string;
  payment_method_types: string[];
  payment_method_options: Record<string, unknown>;
  payment_method_configuration_details: Record<string, unknown>;
  automatic_payment_methods: Record<string, unknown>;
  next_action: null;
}

export type paymentType = "STRIPE" | "CASH" | "CREDIT_CARD";

export type PaymentFormType = {
  paymentType: paymentType;
  id: string;
};

export enum PaymentTypesEnum {
  STRIPE = "STRIPE",
  PAYPAL = "PAYPAL",
  CASH = "CASH",
  CREDIT_CARD = "CREDIT_CARD",
  VOUCHER = "VOUCHER",
}

export enum PaymentStatusesEnum {
  SUCCEEDED = "SUCCEEDED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  UNPAID = "UNPAID",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REFUND_PENDING = "REFUND_PENDING",
}

export interface CreateVoucherResponce {
  voucherId: string;
}
