// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addButton_add_button__74gXX {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #32976b;
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
  border: #32976b solid 2px;
  border-radius: 5px;
  width: 100%;
  max-width: 40px;
  height: 40px;
}
.addButton_add_button__74gXX:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  background-color: #ff6138;
  border: #ff6138 solid 2px;
  color: white;
}
.addButton_add_button__74gXX:disabled {
  background-color: #f0f0f0;
  color: #b3b3b3;
  cursor: not-allowed;
  border: #f0f0f0 solid 2px;
}
.addButton_add_button__74gXX:disabled:hover {
  background-color: #f0f0f0;
  color: #b3b3b3;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Buttons/AddButton/addButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;EACA,gCAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AACF;AACE;EACE,sBAAA;EACA,gCAAA;EACA,yBAAA;EACA,yBAAA;EACA,YAAA;AACJ;AAEE;EACE,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAEI;EACE,yBAAA;EACA,cAAA;AAAN","sourcesContent":[".add_button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  color: #32976b;\n  font-size: 24px;\n  font-weight: 700;\n  padding: 0;\n  margin: 0 10px;\n  transition: all 0.2s ease-in-out;\n  border: #32976b solid 2px;\n  border-radius: 5px;\n  width: 100%;\n  max-width: 40px;\n  height: 40px;\n\n  &:hover {\n    transform: scale(1.05);\n    transition: all 0.2s ease-in-out;\n    background-color: #ff6138;\n    border: #ff6138 solid 2px;\n    color: white;\n  }\n\n  &:disabled {\n    background-color: #f0f0f0;\n    color: #b3b3b3;\n    cursor: not-allowed;\n    border: #f0f0f0 solid 2px;\n\n    &:hover {\n      background-color: #f0f0f0;\n      color: #b3b3b3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add_button": `addButton_add_button__74gXX`
};
export default ___CSS_LOADER_EXPORT___;
