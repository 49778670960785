// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchUserInput_find_item__po\\+YG {
  display: flex;
  align-items: center;
  gap: 10px;
}
.searchUserInput_find_item__po\\+YG button {
  background-color: #ff6138;
  color: #fff;
  border: none;
  padding: 8px 15px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
}
.searchUserInput_find_item__po\\+YG button:disabled {
  background-color: #e5e5e5;
  color: #fff;
  cursor: not-allowed;
}
.searchUserInput_find_item__po\\+YG button:disabled:hover {
  background-color: #e5e5e5;
  opacity: 1;
}
.searchUserInput_find_item__po\\+YG button img {
  width: 20px;
  height: 20px;
}
.searchUserInput_find_item__po\\+YG button:hover {
  background-color: rgba(255, 96, 56, 0.4823529412);
  opacity: 0.8;
}
.searchUserInput_find_item__po\\+YG input {
  height: 40px;
  width: 100%;
  padding: 13px 15px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}
.searchUserInput_find_item__po\\+YG ul {
  padding: 0 20px;
  height: 190px;
  width: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.searchUserInput_find_item__po\\+YG ul li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}
.searchUserInput_find_item__po\\+YG ul li:hover {
  cursor: pointer;
  background-color: #fff;
  color: #ff6138;
}`, "",{"version":3,"sources":["webpack://./src/app/components/FormFields/SearchUserInput/searchUserInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;AACE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACE,yBAAA;EACA,WAAA;EACA,mBAAA;AACN;AACM;EACE,yBAAA;EACA,UAAA;AACR;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAII;EACE,iDAAA;EACA,YAAA;AAFN;AAME;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAJJ;AAOE;EACE,eAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AALJ;AAOI;EACE,gBAAA;EACA,eAAA;EACA,gCAAA;EACA,eAAA;AALN;AAOM;EACE,eAAA;EACA,sBAAA;EACA,cAAA;AALR","sourcesContent":[".find_item {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  button {\n    background-color: #ff6138;\n    color: #fff;\n    border: none;\n    padding: 8px 15px;\n    height: 40px;\n    font-size: 16px;\n    font-weight: 400;\n    border-radius: 5px;\n    cursor: pointer;\n\n    &:disabled {\n      background-color: #e5e5e5;\n      color: #fff;\n      cursor: not-allowed;\n\n      &:hover {\n        background-color: #e5e5e5;\n        opacity: 1;\n      }\n    }\n\n    img {\n      width: 20px;\n      height: 20px;\n    }\n\n    &:hover {\n      background-color: #ff60387b;\n      opacity: 0.8;\n    }\n  }\n\n  input {\n    height: 40px;\n    width: 100%;\n    padding: 13px 15px;\n    border: none;\n    outline: none;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 1;\n  }\n\n  ul {\n    padding: 0 20px;\n    height: 190px;\n    width: 300px;\n    overflow: hidden;\n    overflow-y: scroll;\n\n    li {\n      list-style: none;\n      padding: 10px 0;\n      border-bottom: 1px solid #e5e5e5;\n      font-size: 14px;\n\n      &:hover {\n        cursor: pointer;\n        background-color: #fff;\n        color: #ff6138;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"find_item": `searchUserInput_find_item__po+YG`
};
export default ___CSS_LOADER_EXPORT___;
