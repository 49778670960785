import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ReducerKeys } from "../types";
import { axiosBaseQuery, PathBuilder, RequestTypes } from "../../utils";
import {
  credential,
  IMessageResponse,
  IPaymentResponse,
  PaymentFormType,
} from "../../shared";

export const paymentApi = createApi({
  reducerPath: ReducerKeys.PAYMENTS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [
    ReducerKeys.PAYMENTS_API_REDUCER_KEY,
    ReducerKeys.VISITS_API_REDUCER_KEY,
  ],
  endpoints: (build) => ({
    createPayment: build.mutation<IPaymentResponse, { visitId: string }>({
      query: ({ visitId }) => ({
        url: PathBuilder.paymentApi.createPayment(visitId),
        method: RequestTypes.post,
      }),
      invalidatesTags: [ReducerKeys.PAYMENTS_API_REDUCER_KEY],
    }),
    markAsPaid: build.mutation<IMessageResponse, PaymentFormType>({
      query: (data) => ({
        url: PathBuilder.paymentApi.markAsPaid(data.id),
        method: RequestTypes.post,
        data: { paymentType: data.paymentType },
      }),
      invalidatesTags: [
        ReducerKeys.VISITS_API_REDUCER_KEY,
        ReducerKeys.PAYMENTS_API_REDUCER_KEY,
      ],
    }),
    refundPayment: build.mutation<IMessageResponse, { id: string }>({
      query: ({ id }) => ({
        url: PathBuilder.paymentApi.refundPayment(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [
        ReducerKeys.VISITS_API_REDUCER_KEY,
        ReducerKeys.PAYMENTS_API_REDUCER_KEY,
      ],
    }),
    approveRefund: build.mutation<IMessageResponse, { id: string }>({
      query: ({ id }) => ({
        url: PathBuilder.paymentApi.approveRefund(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [
        ReducerKeys.VISITS_API_REDUCER_KEY,
        ReducerKeys.PAYMENTS_API_REDUCER_KEY,
      ],
    }),
    payWithVoucherByAdmin: build.mutation<
      IMessageResponse,
      { id: string; voucherCode: string }
    >({
      query: ({ id, voucherCode }) => ({
        url: PathBuilder.paymentApi.payWithVoucherByAdmin(id),
        method: RequestTypes.post,
        data: { key: voucherCode },
      }),
      invalidatesTags: [
        ReducerKeys.VISITS_API_REDUCER_KEY,
        ReducerKeys.PAYMENTS_API_REDUCER_KEY,
      ],
    }),
    payWithVoucherByUser: build.mutation<
      IMessageResponse,
      { id: string; voucherCode: string }
    >({
      query: ({ id, voucherCode }) => ({
        url: PathBuilder.paymentApi.payWithVoucherByUser(id),
        method: RequestTypes.post,
        data: { key: voucherCode },
      }),
      invalidatesTags: [
        ReducerKeys.VISITS_API_REDUCER_KEY,
        ReducerKeys.PAYMENTS_API_REDUCER_KEY,
      ],
    }),
  }),
});
