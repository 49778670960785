import React, { Suspense, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getClearStorage } from "./store/store";
import { ServiceAsync } from "./pages/Service";
import { HomeAsync } from "./pages/Home";
import { NewsAsync } from "./pages/News";
import { NewsDetailsAsync } from "./pages/NewsDetails";
import { AboutUsAsync } from "./pages/AboutUs";
import { ContactsAsync } from "./pages/Contacts";
import { DoctorsAsync } from "./pages/Doctors";
import { DoctorDetailsAsync } from "./pages/DoctorDetails";
import { AppointmentAsync } from "./pages/Appointment";
import { ReviewsAsync } from "./pages/Reviews";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { AlertNotification } from "./components/AlertNotification";
import { ILoginDto, IRegisterDto, RoutesEnum } from "./shared";
import ScrollToTop from "./shared/helpers/ScrollToTop";
import { TokenService } from "./utils/restClient/tokenService";
import { authApi, userApi } from "./store/services";
import { useAppDispatch } from "./hooks";
import { showError, showSuccessAlert } from "./store/reducers";
import { AdminPanelLayout } from "./components/AdminPanel";

import {
  VisitsHistoryAsync,
  VisitsCalendarAsync,
  VisitFormAsync,
  UserProfileAsync,
  UpdateServiceAsync,
  AdminServicesAsync,
  CreateServiceAsync,
  UpdateRehabilitatorAsync,
  RehabilitatorsAsync,
  RehabilitatorDetailsAsync,
  UpdatePatientAsync,
  PatientsAsync,
  PatientDetailsAsync,
  MedicalCardAsync,
  HospitalsDataAsync,
  CreateHospitalAsync,
  IndividualExercisesTableAsync,
  DailyExercisesTableAsync,
  UpdateDoctorAsync,
  AdminDoctorsAsync,
  AdminDoctorDetailsAsync,
  CreateUserAsync,
  ScheduleAsync,
  AdminReviewsAsync,
  ReviewDetailsAsync,
  UploadPhotoFormAsync,
  VisitPaymentAsync,
  VouchersAsync,
} from "./pages/AdminPanel";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import { initI18n } from "./shared/i18n";
import { PageLoader } from "./pages/PageLoader";
import { GalleryAsync } from "./pages/AdminPanel/Patients/Gallery";
import {
  ArticlesTableAsync,
  CreateArticleAsync,
  UpdateArticleAsync,
} from "./pages/AdminPanel/Articles";
import { FAQAsync } from "./pages/FAQ";
import { PrivateRoute } from "./components/PrivateRoute";
import "./App.css";
import { NotFound } from "./pages/404";
import { GiftCertificateAsync } from "./pages/GiftCertificate";
import { Payment } from "./pages/GiftCertificate/Payment";
import { VoucherDetailsAsync } from "./pages/AdminPanel/Vouchers/VoucherDetails/VoucherDetails.async";

initI18n();

function App() {
  const { t } = useTranslation();
  const token = TokenService.getAccessToken();
  const isAuth = !!token;

  const [pathname, setPathname] = useState("");
  const [trigger, setTrigger] = useState(isAuth);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const dispatch = useAppDispatch();

  const [handleRegistration] = authApi.useRegisterMutation();
  const [handleLogin] = authApi.useLoginMutation();
  const [handleLogout] = authApi.useLogoutMutation();

  const { data: fetchedCurrentUser } = userApi.useFetchCurrentUserQuery(
    undefined,
    {
      skip: !trigger,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleOpenModal = useCallback((value: string) => {
    if (value === "login") {
      setShowLoginModal(true);
    }
    if (value === "question") {
      setShowQuestionModal(true);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowLoginModal(false);
    setShowQuestionModal(false);
  }, []);

  const handleRegisterRequest = async (
    values: IRegisterDto,
    form: FormApi<IRegisterDto>
  ) => {
    try {
      values.firstName = values.firstName.trim();
      values.lastName = values.lastName.trim();
      if (values.email) {
        values.email = values.email.trim();
      } else {
        delete values.email;
      }
      values.phoneNumber = values.phoneNumber.trim();
      values.password = values.password.trim();

      await handleRegistration(values).unwrap();
      form.restart();
      dispatch(showSuccessAlert(t("alert.successfulRegistration")));
    } catch (error: any) {
      dispatch(
        showError(
          t([`alert.errors.${error.data.message}`, "alert.somethingWentWrong"])
        )
      );
    }
  };

  const handleLoginRequest = async (values: ILoginDto) => {
    try {
      values.login = values.login.trim();
      values.password = values.password.trim();
      await handleLogin(values).unwrap();

      setTrigger(true);

      handleCloseModal();
    } catch (error: any) {
      dispatch(
        showError(
          t([`alert.errors.${error.data.message}`, "alert.somethingWentWrong"])
        )
      );
    }
  };

  const handleLogoutRequest = async () => {
    try {
      setTrigger(false);
      await handleLogout();
    } catch (error: any) {
      dispatch(showError(t("alert.somethingWentWrong")));
    }
  };

  useEffect(() => {
    getClearStorage();
  }, []);

  const wrapPrivateRoute = (element: JSX.Element, isAuth: boolean) => {
    return <PrivateRoute isAuth={isAuth}>{element}</PrivateRoute>;
  };

  return (
    <div className="App fade_in">
      <Router>
        <ScrollToTop setPathname={setPathname} />
        <Suspense fallback={<PageLoader />}>
          {!pathname.includes(RoutesEnum.admin) && (
            <Header
              fetchedCurrentUser={fetchedCurrentUser}
              showLoginModal={showLoginModal}
              showQuestionModal={showQuestionModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              handleRegisterRequest={handleRegisterRequest}
              handleLoginRequest={handleLoginRequest}
              handleLogoutRequest={handleLogoutRequest}
            />
          )}

          <Routes>
            <Route
              path={RoutesEnum.initialRoute}
              index
              element={<HomeAsync />}
            />
            <Route
              path={RoutesEnum.giftCertificate}
              element={<GiftCertificateAsync />}
            />
            <Route
              path={`${RoutesEnum.giftCertificate}/payment`}
              element={<Payment />}
            />
            <Route
              path={RoutesEnum.serviseDetails}
              element={<ServiceAsync />}
            />
            <Route path={RoutesEnum.articles} element={<NewsAsync />} />
            <Route
              path={RoutesEnum.articlesDetails}
              element={<NewsDetailsAsync />}
            />
            <Route path={RoutesEnum.about} element={<AboutUsAsync />} />
            <Route path={RoutesEnum.contacts} element={<ContactsAsync />} />
            <Route path={RoutesEnum.doctors} element={<DoctorsAsync />} />
            <Route
              path={RoutesEnum.doctorDetails}
              element={<DoctorDetailsAsync />}
            />
            <Route path={RoutesEnum.reviews} element={<ReviewsAsync />} />
            <Route
              path={RoutesEnum.appointment}
              element={
                <AppointmentAsync
                  currentUser={fetchedCurrentUser}
                  handleLoginRequest={handleLoginRequest}
                />
              }
            />
            <Route path={RoutesEnum.faq} element={<FAQAsync />} />

            <Route
              element={
                <AdminPanelLayout
                  handleLogoutRequest={handleLogoutRequest}
                  currentUser={fetchedCurrentUser}
                />
              }
            >
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patients}`}
                element={wrapPrivateRoute(<PatientsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}`}
                element={wrapPrivateRoute(<PatientDetailsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientUpdate}`}
                element={wrapPrivateRoute(<UpdatePatientAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.doctors}`}
                element={wrapPrivateRoute(<AdminDoctorsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.doctorDetails}`}
                element={wrapPrivateRoute(<AdminDoctorDetailsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.doctorUpdate}`}
                element={wrapPrivateRoute(<UpdateDoctorAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.rehabilitators}`}
                element={wrapPrivateRoute(<RehabilitatorsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.rehabilitatorDetails}`}
                element={wrapPrivateRoute(
                  <RehabilitatorDetailsAsync />,
                  isAuth
                )}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.rehabilitatorUpdate}`}
                element={wrapPrivateRoute(<UpdateRehabilitatorAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.createUser}`}
                element={wrapPrivateRoute(<CreateUserAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.profile}`}
                element={wrapPrivateRoute(<UserProfileAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.services}`}
                element={wrapPrivateRoute(<AdminServicesAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.createService}`}
                element={wrapPrivateRoute(<CreateServiceAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.updateService}`}
                element={wrapPrivateRoute(<UpdateServiceAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.visitForm}`}
                element={wrapPrivateRoute(<VisitFormAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.newVisit}`}
                element={wrapPrivateRoute(<VisitFormAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.editVisitForm}`}
                element={wrapPrivateRoute(<VisitFormAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.exercisesTable}`}
                element={wrapPrivateRoute(
                  <IndividualExercisesTableAsync />,
                  isAuth
                )}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.dailyVisits}`}
                element={wrapPrivateRoute(<DailyExercisesTableAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.visitsHistory}`}
                element={wrapPrivateRoute(<VisitsHistoryAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.visitPayment}/:visitId`}
                element={wrapPrivateRoute(<VisitPaymentAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.visitsCalendar}`}
                element={wrapPrivateRoute(<VisitsCalendarAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.hospitals}`}
                element={wrapPrivateRoute(<HospitalsDataAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.createHospital}`}
                element={wrapPrivateRoute(<CreateHospitalAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.myTable}`}
                element={wrapPrivateRoute(
                  <IndividualExercisesTableAsync />,
                  isAuth
                )}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.medicalCard}`}
                element={wrapPrivateRoute(<MedicalCardAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.schedules}`}
                element={wrapPrivateRoute(<ScheduleAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.reviews}`}
                element={wrapPrivateRoute(<AdminReviewsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.reviewDetails}`}
                element={wrapPrivateRoute(<ReviewDetailsAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.uploadPhoto}`}
                element={wrapPrivateRoute(<UploadPhotoFormAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.patientDetails}${RoutesEnum.gallery}`}
                element={wrapPrivateRoute(<GalleryAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.gallery}`}
                element={wrapPrivateRoute(<GalleryAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.articles}`}
                element={wrapPrivateRoute(<ArticlesTableAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.createArticles}`}
                element={wrapPrivateRoute(<CreateArticleAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.updateArticles}`}
                element={wrapPrivateRoute(<UpdateArticleAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.giftCertificate}`}
                element={wrapPrivateRoute(<VouchersAsync />, isAuth)}
              />
              <Route
                path={`${RoutesEnum.admin}${RoutesEnum.giftCertificate}/:voucherId`}
                element={wrapPrivateRoute(<VoucherDetailsAsync />, isAuth)}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <AlertNotification />
        {!pathname.includes(RoutesEnum.admin) && <Footer />}
      </Router>
    </div>
  );
}

export default App;
