// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectField_select_wrap__bJtc7 {
  display: flex;
}
.selectField_select_wrap__bJtc7 select {
  height: 35px;
  min-width: 150px;
  max-width: 200px;
  padding: 0 15px;
  padding-right: 20px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}
.selectField_select_wrap__bJtc7 .selectField_status_title__nA5VM {
  height: 35px;
  padding: 5px 10px;
  background-color: white;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.015em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/FormFields/SelectStatus/selectField.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;AAAJ","sourcesContent":[".select_wrap {\n  display: flex;\n\n  select {\n    height: 35px;\n    min-width: 150px;\n    max-width: 200px;\n    padding: 0 15px;\n    padding-right: 20px;\n    border: none;\n    outline: none;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 1;\n  }\n\n  .status_title {\n    height: 35px;\n    padding: 5px 10px;\n    background-color: white;\n    font-size: 16px;\n    line-height: 25px;\n    letter-spacing: -0.015em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_wrap": `selectField_select_wrap__bJtc7`,
	"status_title": `selectField_status_title__nA5VM`
};
export default ___CSS_LOADER_EXPORT___;
