import React, { FC } from "react";
import { turn_on } from "@jat-med/assets";
import { ITableActivateButtonProps } from "./tableActivateButton.interface";
import styles from "./tableActivateButton.module.scss";

export const TableActivateButton: FC<ITableActivateButtonProps> = ({
  onClick,
}) => {
  return (
    <button className={styles.table_activate_button} onClick={onClick}>
      <img src={turn_on} alt="activate_btn" />
    </button>
  );
};
