// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentForm_form__rXq6k {
  width: 100%;
  margin-top: 30px;
}
.paymentForm_form__rXq6k button {
  margin: 20px auto 0;
}

.paymentForm_paymentSuccessfulWrap__xVhKP {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 20px;
  flex-direction: column;
}
.paymentForm_paymentSuccessfulWrap__xVhKP h2 {
  font-size: 32px;
  font-weight: 700;
}
.paymentForm_paymentSuccessfulWrap__xVhKP .paymentForm_logo_link__T\\+NR5 {
  position: relative;
}
.paymentForm_paymentSuccessfulWrap__xVhKP .paymentForm_logo_link__T\\+NR5 svg {
  position: absolute;
}

.paymentForm_fade_in__pDSHm {
  animation: paymentForm_fadeIn__ZeG\\+l 0.3s ease-in forwards;
}

@keyframes paymentForm_fadeIn__ZeG\\+l {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/Forms/PaymentForm/paymentForm.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAEI;EACE,kBAAA;AAAN;;AAKA;EACE,2DAAA;AAFF;;AAKA;EACE;IACE,UAAA;EAFF;EAIA;IACE,UAAA;EAFF;AACF","sourcesContent":[".form {\n  width: 100%;\n  margin-top: 30px;\n  button {\n    margin: 20px auto 0;\n  }\n}\n\n.paymentSuccessfulWrap {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  gap: 20px;\n  flex-direction: column;\n\n  h2 {\n    font-size: 32px;\n    font-weight: 700;\n  }\n\n  .logo_link {\n    position: relative;\n\n    svg {\n      position: absolute;\n    }\n  }\n}\n\n.fade_in {\n  animation: fadeIn 0.3s ease-in forwards;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `paymentForm_form__rXq6k`,
	"paymentSuccessfulWrap": `paymentForm_paymentSuccessfulWrap__xVhKP`,
	"logo_link": `paymentForm_logo_link__T+NR5`,
	"fade_in": `paymentForm_fade_in__pDSHm`,
	"fadeIn": `paymentForm_fadeIn__ZeG+l`
};
export default ___CSS_LOADER_EXPORT___;
