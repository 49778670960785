// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentStatusTooltip_tooltip_text__kiT2x {
  visibility: hidden;
  min-width: 140px;
  font-size: 14px;
  background-color: rgba(50, 151, 107, 0.8901960784);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 0;
  right: 100%;
  bottom: 0;
  margin-left: -65px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Payment/PaymentStatusTooltip/paymentStatusTooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kDAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EAEA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;AAAF","sourcesContent":[".tooltip_text {\n  visibility: hidden;\n  min-width: 140px;\n  font-size: 14px;\n  background-color: #32976be3;\n  color: #fff;\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px;\n\n  /* Position the tooltip */\n  position: absolute;\n  z-index: 0;\n  right: 100%;\n  bottom: 0;\n  margin-left: -65px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip_text": `paymentStatusTooltip_tooltip_text__kiT2x`
};
export default ___CSS_LOADER_EXPORT___;
