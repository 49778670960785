// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timePicker_error_message__Y74Hc {
  color: red;
  font-size: 12px;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/FormFields/TimePicker/timePicker.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".error_message {\n  color: red;\n  font-size: 12px;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_message": `timePicker_error_message__Y74Hc`
};
export default ___CSS_LOADER_EXPORT___;
