import moment from "moment";
import { FC } from "react";
import styles from "./paymentStatusTooltip.module.scss";
import { useTranslation } from "react-i18next";

export interface PaymentStatusTooltipProps {
  className?: string;
  time?: string;
  firstName?: string;
  lastName?: string;
  paymentType?: string;
}

export const PaymentStatusTooltip: FC<PaymentStatusTooltipProps> = ({
  className,
  time,
  firstName,
  lastName,
  paymentType,
}) => {
  const { t } = useTranslation();
  return (
    <span className={`${className} ${styles.tooltip_text}`}>
      <span>{moment(time as string).format("DD.MM.YYYY HH:mm")}</span>
      <br />
      <span>{firstName}</span>
      <br />
      <span>{lastName}</span>
      <br />
      <span>{t(paymentType as string)}</span>
    </span>
  );
};
