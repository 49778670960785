import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentForm } from "./PaymentForm";
import { StripePaymentFormType } from "./paymentForm.interface";
import { CURRENCIES } from "../../../shared";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

export const StripePaymentForm = ({
  options,
  ...rest
}: StripePaymentFormType) => {
  const defaultOptions = {
    mode: "payment" as const,
    amount: 0,
    currency: "pln",
    appearance: {
      theme: "stripe" as const,
    },
    ...options,
  };

  const isCorrectCurrency = CURRENCIES.find(
    (currency) =>
      currency.value === rest.currencySymbol &&
      options.amount &&
      options.amount >= currency.min
  );

  return (
    <>
      {isCorrectCurrency ? (
        <Elements stripe={stripePromise} options={defaultOptions}>
          <PaymentForm {...rest} />
        </Elements>
      ) : (
        <div>Invalid currency or amount</div>
      )}
    </>
  );
};
