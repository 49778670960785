import { useState, useTransition } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useAppDispatch } from "../../../hooks";
import { showError } from "../../../store/reducers";
import { IPaymentResponse, RoutesEnum } from "../../../shared";
import styles from "./paymentForm.module.scss";
import { Button } from "../../Buttons";
import { useTranslation } from "react-i18next";
import { SucceededPayment } from "./SucceededPayment";

type PaymentFormProps = {
  isShippingCost?: boolean;
  price: number;
  shippingCost: number;
  currencySymbol: string;
  singlePayment?: boolean;
  handlePayment: () => Promise<{ data: IPaymentResponse } | { error: unknown }>;
  hideRedirectAfterPayment?: boolean;
};

export const PaymentForm = ({
  price,
  currencySymbol,
  handlePayment,
  hideRedirectAfterPayment,
}: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isPending, startTransition] = useTransition();
  const [isSuccessedPayment, setIsSuccessedPayment] = useState(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const fullPrice = price;
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }
    startTransition(() => {
      (async () => {
        const paymentResponse = await handlePayment();
        if ("data" in paymentResponse && paymentResponse.data?.client_secret) {
          const { error } = await stripe.confirmPayment({
            elements,
            clientSecret: paymentResponse.data.client_secret as string,
            confirmParams: {
              return_url: `${window.location.origin}${RoutesEnum.admin}${RoutesEnum.visitsHistory}`,
            },
            redirect: "if_required",
          });

          if (error) {
            dispatch(showError(error.message || t("alert.somethingWentWrong")));
          } else {
            setIsSuccessedPayment(true);
          }
        } else {
          dispatch(showError(t("alert.somethingWentWrong")));
        }
      })();
    });
  }

  return (
    <>
      {isSuccessedPayment ? (
        <SucceededPayment hideRedirect={hideRedirectAfterPayment} />
      ) : (
        <form onSubmit={handleSubmit} className={styles.form}>
          <PaymentElement />
          <Button
            type="submit"
            className="orange"
            disabled={isPending || !stripe || !elements}
            label={
              isPending
                ? "Loading..."
                : `${t("buttons.pay")} ${fullPrice} ${currencySymbol}`
            }
          />
        </form>
      )}
    </>
  );
};
